<template>
  <div class="">
    <el-form :model="form" :rules="rules" ref="form">
      <TopBarTitleComponent title="Productos" :showBackIcon="showBackIcon">
        <template slot="right-area">
          <div class="d-flex justify-content-end align-items-center">
            <ButtonPrimaryComponent
              title="Guardar"
              @onSaveClick="handleSaveClick"
            />
          </div>
        </template>
      </TopBarTitleComponent>

      <b-container fluid class="pb-4 px-lg-4">
        <b-row>
          <b-col xs="12" sm="12" md="6">
            <div class="card box-shadow">
              <div class="card-body">
                <el-form-item prop="image">
                  <FileUploadComponent
                    title="Subir imagen"
                    :preview="form.image"
                    :showFileList="false"
                    @fileBase64="onFileBase64"
                  />
                </el-form-item>

                <el-form-item prop="name">
                  <slot name="label">
                    <label class="font-semi-bold m-0"
                      >Nombre del Servicio / Producto</label
                    >
                  </slot>
                  <el-input type="text" v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item prop="code">
                  <slot name="label">
                    <label class="font-semi-bold m-0">Código/SKU</label>
                  </slot>
                  <el-input type="text" v-model="form.code"></el-input>
                </el-form-item>
                <el-form-item prop="amount">
                  <slot name="label">
                    <label class="font-semi-bold m-0">Precio</label>
                  </slot>
                  <InputAmount
                    :amount="form.amount"
                    @onChange="amount => onInputAmount(amount, 'amount')"
                  />
                </el-form-item>
                <el-form-item prop="taxes_id">
                  <slot name="label">
                    <label class="font-semi-bold m-0">IVA</label>
                  </slot>
                  <SelectTaxes
                    :setTaxesId="form.taxes_id"
                    @onSelectTax="onSelectTax"
                  />
                </el-form-item>
                <el-form-item prop="categories_id">
                  <SelectCategories
                    :setSelectCategories="form.categories_id"
                    @onChangeSelect="onChangeSelectCategories"
                  />
                </el-form-item>
                <el-form-item prop="description">
                  <InputTextComponent
                    maxlength="null"
                    :showWordLimit="false"
                    inputProp="description"
                    @onInputChange="onInputChange"
                    :value.sync="form.description"
                  >
                    <template slot="label">
                      <label class="font-semi-bold m-0"
                        >Descripción de la categoría
                        <span class="text-muted"
                          >(detalle a visualizar en los documentos)</span
                        ></label
                      >
                    </template>
                  </InputTextComponent>
                </el-form-item>
              </div>
            </div>
          </b-col>

          <b-col xs="12" sm="12" md="6">
            <div class="card box-shadow mb-4">
              <div class="card-body">
                <h5
                  class="h5 text-color-primary font-semi-bold line-bottom pb-2"
                >
                  Tipo de Producto
                </h5>
                <el-form-item prop="type">
                  <el-select v-model="form.type" placeholder="Seleccionar">
                    <el-option
                      v-for="(option, index) in productTypesCrear"
                      :key="index"
                      :label="option.label"
                      :value="option.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>

            <div class="card box-shadow mb-4">
              <div class="card-body">
                <h5
                  class="h5 text-color-primary font-semi-bold line-bottom pb-2"
                >
                  Estado de Producto
                </h5>
                <el-form-item prop="status_product">
                  <SwitchStatus
                    :setValue.sync="form.status_product"
                    @onStatus="onStatusProduct"
                  />
                </el-form-item>
              </div>
            </div>

            <TransitionFadeComponent>
              <ProductVariations
                v-if="form.type === 'variable'"
                @onSaveVariations="onSaveVariations"
              />
            </TransitionFadeComponent>

            <TransitionFadeComponent>
              <div class="card box-shadow mb-4" v-if="form.type === 'simple'">
                <div class="card-body">
                  <h5
                    class="h5 text-color-primary font-semi-bold line-bottom pb-2"
                  >
                    Gestión de inventario
                  </h5>
                  <TransitionFadeComponent
                    v-for="(stock, index) in form.stocks"
                    :key="index"
                  >
                    <el-form-item prop="stock" v-if="form.is_stock_counter">
                      <slot name="label">
                        <label class="font-semi-bold m-0">
                          {{ stock.establishment }}
                        </label>
                      </slot>
                      <el-input-number
                        :controls="false"
                        placeholder="Cantidad"
                        v-model="form.stocks[index].quantity"
                      ></el-input-number>
                    </el-form-item>
                  </TransitionFadeComponent>

                  <el-form-item prop="is_stock_counter">
                    <SwitchTracking
                      :setValue.sync="form.is_stock_counter"
                      @onTracking="onTrackingProduct"
                    />
                  </el-form-item>
                </div>
              </div>
            </TransitionFadeComponent>
          </b-col>
        </b-row>
      </b-container>
    </el-form>
  </div>
</template>

<script>
import TopBarTitleComponent from "../../../components/TopBarTitle";
import ButtonPrimaryComponent from "../../../components/ButtonPrimary";
import FileUploadComponent from "../../../components/FileUpload";
import Validate from "@/assets/validate";
import catalogueService, {
  productTypesCrear
} from "../../services/catalogueService";
import InputTextComponent from "../../../components/InputText";
import SelectTaxes from "./SelectTaxes";
import SelectCategories from "../../../components/SelectCategories";
import ProductVariations from "./Variations";
import TransitionFadeComponent from "@/components/Transitions/Fade";
import SwitchTracking from "./SwitchTracking";
import SwitchStatus from "./SwitchStatus";
import InputAmount from "@/components/General/InputAmount";
import tradeService from "../../../profile/services/tradeService";

export default {
  name: "ProductAddComponent",
  data() {
    return {
      productTypesCrear,
      apiResponse: {
        message: null
      },
      form: {
        categories_id: [],
        name: "",
        description: "",
        code: "",
        amount: 0,
        stocks: [],
        is_stock_counter: false,
        status_product: false,
        image: "",
        taxes_id: null,
        type: "simple",
        variations: []
      },
      rules: {
        name: [Validate.rules.required()],
        code: [Validate.rules.required()],
        taxes_id: [Validate.rules.required()],
        amount: [Validate.rules.numeric("number")]
        //categories_id: [Validate.rules.required("change")]
        // stock: [Validate.rules.numeric("integer")],
        // description: [Validate.rules.required()]
      },
      stablishments: []
    };
  },
  computed: {
    getModuleCatalog() {
      const { product } = this.$store.getters.getModule("catalog");
      return product.add;
    }
  },
  methods: {
    onFileBase64(base64) {
      this.form.image = base64;
    },
    onChangeInput(data) {
      this.form = { ...this.form, ...data };
    },
    onInputAmount({ amount }, key) {
      this.form[key] = amount;
    },
    onInputChange(value) {
      this.form.description = value;
    },
    onTrackingProduct({ tracking }) {
      this.form.is_stock_counter = tracking;
    },
    onStatusProduct({ status }) {
      this.form.status_product = status;
    },
    onChangeSelectCategories({ selectCategories }) {
      this.form.categories_id = selectCategories;
    },
    onSelectTax({ id }) {
      this.form.taxes_id = id;
    },
    onSaveVariations({ variations }) {
      this.form.variations = variations;
    },
    handleSaveClick() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.$store.dispatch("toggleRequestLoading");
          try {
            let response = await catalogueService
              .addProducts(this.form)
              .finally(() => {
                this.$store.dispatch("toggleRequestLoading");
              });
            const { success, message, product } = response.data;
            if (success) {
              this.$refs.form.resetFields();
              this.onFileBase64("");

              this.$notifications.success({
                message
              });

              this.$emit("onSuccess", { product });
            }
          } catch (error) {
            return false;
          }
        }
      });
    },
    async getStablishments() {
      try {
        const response = await tradeService.getStablishments();
        const establishmentsData = response.data?.data || [];
        this.stablishments = establishmentsData;

        this.form.stocks = establishmentsData.reverse().map(element => ({
          establishment: element.name,
          establishment_id: element.id,
          quantity: 0
        }));
      } catch (error) {
        return false;
      }
    },
    autoSelectSingleOption() {
      // Si hay solo una opción, selecciona automáticamente esa opción
      if (this.productTypesCrear.length === 1) {
        this.form.type = this.productTypesCrear[0].value;
      }
    }
  },
  components: {
    TopBarTitleComponent,
    ButtonPrimaryComponent,
    FileUploadComponent,
    InputTextComponent,
    SelectCategories,
    ProductVariations,
    TransitionFadeComponent,
    SwitchTracking,
    SwitchStatus,
    InputAmount,
    SelectTaxes
  },
  props: {
    showBackIcon: {
      default: true,
      type: Boolean
    }
  },
  mounted() {
    this.form = this.getModuleCatalog;
    this.getStablishments();
    this.autoSelectSingleOption();
  }
};
</script>

<style></style>
