<template>
  <div class="">
    <b-row
      v-for="(attribute, indexAttribute) in allAttributes"
      :key="indexAttribute"
    >
      <b-col xs="12" sm="12" md="6">
        <el-select
          placeholder="Atributo"
          v-model="attribute.id"
          @change="onSeletedAttributes"
        >
          <el-option
            v-for="(a, index) in attributes"
            :key="index"
            :label="a.name"
            :value="a.id"
          ></el-option>
        </el-select>
      </b-col>
      <b-col xs="12" sm="12" md="6">
        <el-select
          placeholder="Términos"
          v-model="attribute.terms"
          multiple
          filterable
          @change="onSeletedAttributes"
        >
          <el-option
            v-for="(term, index) in getAttributeTerms(attribute)"
            :key="index"
            :label="term.name"
            :value="term.id"
          ></el-option>
        </el-select>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import catalogueService from "../../services/catalogueService";

export default {
  name: "SelectProductAttributes",
  data() {
    return {
      attributes: [],
      allAttributes: this.setAttributes
    };
  },
  methods: {
    onSeletedAttributes() {
      this.$emit("onSeletedAttributes", { attributes: this.allAttributes });
    },
    getAttributeTerms({ id: attributeId }) {
      return this.attributes.find(a => a.id === attributeId)?.terms;
    },
    async getAttributes() {
      try {
        let response = await catalogueService.getAttributes();
        const { success, data } = response.data;
        if (success) {
          this.attributes = data;
        }
      } catch (error) {
        return false;
      }
    }
  },
  watch: {
    setAttributes(v) {
      this.allAttributes = v;
    }
  },
  components: {},
  props: {
    setAttributes: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    this.getAttributes();
  }
};
</script>

<style></style>
