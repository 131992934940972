<template>
  <div class="">
    <div class="card box-shadow mb-4">
      <div class="card-body">
        <div
          class="d-flex justify-content-between align-items-center line-bottom"
        >
          <h5 class="h5 text-color-primary font-semi-bold pb-2">
            Variantes de Producto
          </h5>
          <LinkPlusComponent @onClick="onAddVariables" />
        </div>

        <div class="mt-4" v-for="(variation, index) in variations" :key="index">
          <div class="my-3 py-2 bg-color-gray cursor-point">
            <b-row>
              <b-col xs="12" sm="12" md="2">
                <ImagePreviewCircleComponent :urlImage="variation.image" />
              </b-col>
              <b-col xs="12" sm="12" md="4">
                <span>Atributos</span>
                <p class="font-bold text-color-primary m-0">
                  +{{ variation.attributes.length }}
                </p>
              </b-col>
              <b-col xs="12" sm="12" md="3">
                <span>SKU</span>
                <p class="font-bold text-color-primary m-0">
                  {{ variation.sku }}
                </p>
              </b-col>
              <b-col xs="12" sm="12" md="3">
                <span>Precio</span>
                <p class="font-bold text-color-primary m-0">
                  ${{ variation.price }}
                </p>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </div>

    <el-drawer
      title=""
      :visible.sync="dialogVariationsAdd"
      :with-header="false"
      @closed="onCloseVariablesAdd"
      size="50%"
    >
      <section class="wrapper__variants-product-add p-4">
        <h3 class="h3 text-primary font-semi-bold mt-0 mb-4 pb-4">
          Variante de producto
        </h3>
        <el-form :model="form" ref="form">
          <b-row class="mt-4">
            <b-col xs="12" sm="12" md="6">
              <el-form-item prop="image">
                <div class="d-flex justify-content-center align-items-center">
                  <FileUploadComponent
                    :preview="form.image"
                    title="Subir imagen"
                    :showFileList="false"
                    @fileBase64="onFileBase64"
                  />
                </div>
              </el-form-item>
            </b-col>

            <b-col xs="12" sm="12" md="6">
              <el-form-item prop="status">
                <div class="d-flex align-items-center">
                  <SwitchComponent
                    :setValue.sync="form.status"
                    @onChange="onChangeSwitch"
                  />
                  <span class="ml-4 text-color-primary font-bold">Estado</span>
                </div>
              </el-form-item>
              <el-form-item prop="sku">
                <slot name="label">
                  <label class="font-semi-bold m-0">SKU</label>
                </slot>
                <el-input type="text" v-model="form.sku"></el-input>
              </el-form-item>
            </b-col>
          </b-row>

          <b-row class="my-4">
            <b-col xs="12" sm="12" md="6">
              <el-form-item prop="price">
                <slot name="label">
                  <label class="font-semi-bold m-0">Precio</label>
                </slot>
                <InputAmount
                  :amount="form.price"
                  @onChange="amount => onInputAmount(amount, 'price')"
                />
              </el-form-item>
            </b-col>
            <b-col xs="12" sm="12" md="6">
              <el-form-item prop="price_descount">
                <slot name="label">
                  <label class="font-semi-bold m-0">Precio con Descuento</label>
                </slot>
                <InputAmount
                  :amount="form.price_descount"
                  @onChange="amount => onInputAmount(amount, 'price_descount')"
                />
              </el-form-item>
            </b-col>
          </b-row>

          <b-row class="my-4">
            <b-col xs="12" sm="12" md="12">
              <el-form-item prop="description">
                <slot name="label">
                  <label class="font-semi-bold m-0">Descripción</label>
                </slot>
                <el-input type="text" v-model="form.description"></el-input>
              </el-form-item>
            </b-col>
          </b-row>

          <div class="my-4 pb-4">
            <div
              class="d-flex justify-content-between align-items-center line-bottom mb-4"
            >
              <h5 class="h5 font-semi-bold m-0 text-color-secondary">
                Atributos de Producto
              </h5>
              <LinkPlusComponent onlyIcon @onClick="onAddAttributes" />
            </div>

            <el-form-item prop="attributes">
              <SelectProductAttributes
                :setAttributes="form.attributes"
                @onSeletedAttributes="onSeletedAttributes"
              />
            </el-form-item>
          </div>

          <b-row class="my-4">
            <b-col xs="12" sm="12" md="12">
              <h5 class="h5 font-semi-bold line-bottom text-color-secondary">
                Gestión de inventario
              </h5>
              <TransitionFadeComponent>
                <el-form-item prop="stock" v-if="form.is_stock_counter">
                  <slot name="label">
                    <label class="font-semi-bold m-0"
                      >Cantidad en almacén</label
                    >
                  </slot>
                  <el-input-number
                    :controls="false"
                    placeholder="Cantidad"
                    v-model="form.stock"
                  ></el-input-number>
                </el-form-item>
              </TransitionFadeComponent>

              <el-form-item prop="is_stock_counter">
                <SwitchTracking
                  :setValue.sync="form.is_stock_counter"
                  @onTracking="onTrackingVariants"
                />
              </el-form-item>
            </b-col>
          </b-row>

          <b-row class="my-4">
            <b-col xs="12" sm="12" md="12">
              <div class="d-flex justify-content-center align-items-center">
                <ButtonPrimaryComponent
                  title="Agregar"
                  @onSaveClick="onSaveVariations"
                />
              </div>
            </b-col>
          </b-row>
        </el-form>
      </section>
    </el-drawer>
  </div>
</template>

<script>
import LinkPlusComponent from "../../../components/LinkPlus";
import ImagePreviewCircleComponent from "../../../components/ImagePreviewCircle";
import FileUploadComponent from "../../../components/FileUpload";
import SwitchComponent from "../../../components/Switch";
import ButtonPrimaryComponent from "../../../components/ButtonPrimary";
import SwitchTracking from "./SwitchTracking";
import TransitionFadeComponent from "@/components/Transitions/Fade";
import InputAmount from "@/components/General/InputAmount";
import SelectProductAttributes from "./SelectAttributes";

export default {
  name: "ProductVariations",
  data() {
    return {
      dialogVariationsAdd: false,
      variations: [],
      form: {
        sku: null,
        description: null,
        price: 0,
        price_descount: 0,
        stock: 0,
        is_stock_counter: false,
        image: null,
        status: false,
        attributes: []
      }
    };
  },
  computed: {},
  methods: {
    onFileBase64(base64) {
      this.form.image = base64;
    },
    onAddAttributes() {
      this.form.attributes = [
        ...this.form.attributes,
        ...[
          {
            id: null,
            terms: []
          }
        ]
      ];
    },
    onAddVariables() {
      this.dialogVariationsAdd = true;
    },
    onCloseVariablesAdd() {
      this.dialogVariationsAdd = false;
    },
    onTrackingVariants({ tracking }) {
      this.form.is_stock_counter = tracking;
    },
    onInputAmount({ amount }, key) {
      this.form[key] = amount;
    },
    onChangeSwitch({ value }) {
      this.form.status = value;
    },
    onSeletedAttributes({ attributes }) {
      this.form.attributes = attributes;
    },
    onSaveVariations() {
      this.variations = [...this.variations, ...[{ ...this.form }]];
      this.$emit("onSaveVariations", {
        variations: this.variations
      });

      this.$refs.form.resetFields();
      this.onCloseVariablesAdd();
    }
  },
  components: {
    LinkPlusComponent,
    ImagePreviewCircleComponent,
    FileUploadComponent,
    SwitchComponent,
    ButtonPrimaryComponent,
    SwitchTracking,
    TransitionFadeComponent,
    InputAmount,
    SelectProductAttributes
  },
  props: {},
  mounted() {}
};
</script>

<style scoped lang="scss"></style>
